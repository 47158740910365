<template>
  <div class="right sc-width d-flex just-content-between">
    <div class="left-menu">
      <user-left-menu/>
    </div>
    <div class="right-content ">
      <div class="divided"></div>
      <div class="content">
        <div class="tree">
          <vue2-org-tree
            :data="treeData"
            collapsable
            :horizontal=false
            :render-content="renderContent"
            @on-expand="onExpand"
            @on-node-click="NodeClick"
          />
        </div>
        <div class="btn">
          <div class="update-btn">Update</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from "vuex";
// import { Cropper } from "vue-advanced-cropper";
import { dataURLtoBlob, blobToFile } from "@/utils/helper";
// import { MatchMedia } from "vue-component-media-queries";
import api from "@/api";
import S3 from "aws-s3";
import UserLeftMenu from "@/components/userLeftMenu";

const { _queryMyCompany, _editMyCompany, _registerInfo, _country } = api;

const uploader = new S3({
  bucketName: "connexima",
  dirName: "images",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});
export default {
  name: "test-company-profile-basic",

  components: {
    // Cropper,
    // MatchMedia
    UserLeftMenu
  },
  created() {
    _country().then(response => {
      const { data } = response;
      this.countryList = data;
    });
    this.queryMyCompany();
    _registerInfo()
      .then(response => {
        const {
          data: {
            business_type,
            company_annual_revenue,
            company_attend_trade_shows,
            company_focus_area,
            company_focus_industry,
            company_size,
            // interested_in_exhibiting,
            job_title
          }
        } = response;

        this.business_type = business_type;
        this.company_annual_revenue = company_annual_revenue;
        this.company_attend_trade_shows = company_attend_trade_shows;
        this.company_focus_area = company_focus_area;

        this.company_focus_industry = company_focus_industry;
        this.company_size = company_size;
        // this.interested_in_exhibiting = interested_in_exhibiting;
        this.job_title = job_title;
      })
      .catch(({ message }) => {
        this.$Message.error(message);
      });
  },
  computed: {
    ...mapState("user", ["role", "is_administrator"])
  },
  data() {
    return {
      mobileWidth: window.innerWidth < 800,
      activeIndex: "basic",
      countryList: [],
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],

      business_type: [], // Others
      company_annual_revenue: [],
      company_attend_trade_shows: [],
      company_focus_area: [],
      company_focus_industry: [], // Others
      company_size: [],
      // interested_in_exhibiting: [],
      job_title: [],

      editable: false,
      image: null,
      modal: false,
      proClose: false,
      avatar: "",
      loading: false,
      editLoading: false,
      uplodLoading: false,
      form: {
        company_name: "",
        established_year: "",
        company_tax_id: "",
        company_street_address: "",
        company_state_address: "",
        company_country_address: "",
        company_zip_code: "",

        business_type: "",
        company_focus_area: "",
        company_annual_revenue: null,
        company_size: "",
        industry: "",
        company_interests: "",
        company_attend_trade_shows: "",
        proof: null,
        company_focus_industry: null,
        // interested_in_exhibiting: null,
        job_title: null,
        company_city_address: null
      },
      treeData: {
        id: '0',
        label: "DSM COMPANY",
        children: [
          {
            id: '1',
            label: "Sales",
            children: [
              {
                id: '1-1',
                label: "Sale1"
              },
              {
                id: '1-2',
                label: "Sale2"
              },
              {
                id: '1-3',
                label: "Sale3"
              },
              {
                id: '1-4',
                label: "Sale4",
                children: [
                  {
                    id: '2-2',
                    label: "Sale2222"
                  },
                  {
                    id: '2-3',
                    label: "Sale3333"
                  },
                ]
              }
            ]
          },
          {
            id: '2',
            label: "IT",
            children: [
              {
                id: '2-1',
                label: "IT1"
              },
              {
                id: '2-2',
                label: "IT2"
              },
              {
                id: '2-3',
                label: "IT2"
              },
              {
                id: '2-4',
                label: "IT3"
              }
            ]
          },
          {
            id: '3',
            label: "Sales-3",
            children: [
              {
                id: '3-1',
                label: "Sale1"
              },
              {
                id: '3-2',
                label: "Sale2"
              },
              {
                id: '3-3',
                label: "Sale3",
                children: [
                  {
                    id: '2-2',
                    label: "Sale2222"
                  },
                  {
                    id: '2-3',
                    label: "Sale3333"
                  },
                ]
              },
              {
                id: '3-4',
                label: "Sale4"
              }
            ]
          },
        ]
      }
    };
  },
  created(){
    this.toggleExpand(this.treeData,true);
  },
  mounted() {},
  methods: {
    collapse(list) {
      list.forEach((child)=> {
        if (child.expand) {
          child.expand = false;
        }
        child.children && this.collapse(child.children);
      });
    },
    onExpand(e,data) {
      console.log(data,'data')
      if ("expand" in data) {
        data.expand = !data.expand;
        if (!data.expand && data.children) {
          this.collapse(data.children);
        }
      } else {
        this.$set(data, "expand", true);
      }
    },
    toggleExpand(data, val) {
      if (Array.isArray(data)) {
        data.forEach((item)=> {
          this.$set(item, "expand", val);
          if (item.children) {
            this.toggleExpand(item.children, val);
          }
        });
      } else {
        this.$set(data, "expand", val);
        if (data.children) {
          this.toggleExpand(data.children, val);
        }
      }
    },
    NodeClick(e,data){
      console.log(e)
      // e 为 event
      console.log(data)
      // 当前项的所有详情 如：id label children
    },
    renderContent(h, data) {
      return (
        <div class="treeItem">{data.label}</div>
    )
    },
    queryMyCompany() {
      this.loading = true;
      _queryMyCompany()
        .then(response => {
          const { data } = response;
          this.avatar = data.avatar?.url;
          this.form.company_name = data.company_name;
          this.form.established_year = Number(data.established_year);
          this.form.company_tax_id = data.company_tax_id;
          this.form.company_street_address = data.company_street_address;
          this.form.company_state_address = data.company_state_address;
          if (Number(data.company_country_address)) {
            this.form.company_country_address = Number(
              data.company_country_address
            );
          } else {
            this.form.company_country_address = undefined;
          }

          this.form.country_name = data.company_country_info?.name;
          this.form.company_zip_code = data.company_zip_code;
          this.form.company_city_address = data.company_city_address;

          if (this.business_type.includes(data.business_type)) {
            this.form.business_type = data.business_type;
          } else {
            this.form.business_type = "Others";
            this.form.business_type2 = data.business_type;
          }

          if (this.job_title.includes(data.job_title)) {
            this.form.job_title = data.job_title;
          } else {
            this.form.job_title = "Other";
            this.form.job_title2 = data.job_title;
          }

          this.form.company_size = data.company_size;
          this.form.industry = data.industry;
          this.form.company_annual_revenue = data.company_annual_revenue;

          //
          if (data.addition) {
            this.form.business_type = data.addition.business_type;
            this.form.company_focus_area = data.addition.company_focus_area;
            this.form.company_annual_revenue =
              data.addition.company_annual_revenue;
            this.form.company_size = data.addition.company_size;
            this.form.company_interests = data.addition.company_interests;
            // for select data
            this.form.company_attend_trade_shows =
              data.addition.company_attend_trade_shows;
            // for select option
            this.company_attend_trade_shows =
              data.addition.company_attend_trade_shows;

            this.form.proof = data.addition.proof;
            this.form.company_focus_industry =
              data.addition.company_focus_industry;
            // this.form.interested_in_exhibiting =
            //   data.addition.interested_in_exhibiting;
          }
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      const base64Data = canvas.toDataURL();
      const blob = dataURLtoBlob(base64Data);
      const file = blobToFile(blob);

      this.uplodLoading = true;
      uploader
        .uploadFile(file)
        .then(data => {
          this.modal = false;
          this.avatar = data.location;
          this.updateAvatar(data.location);
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodLoading = false;
        });
    },

    uploadImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    updateAvatar(company_avatar) {
      _editMyCompany({
        ...this.form,
        company_avatar
      })
        .then(() => {
          this.$Message.success("success");
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    },

    cancelSubmit() {
      this.editable = false;
      this.queryMyCompany();
    },

    editSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.editLoading = true;
          var formCopy = JSON.parse(JSON.stringify(this.form));
          if (this.form.business_type === "Others" && this.form.business_type2)
            formCopy.business_type = this.form.business_type2;
          if (this.form.job_title === "Other" && this.form.job_title2)
            formCopy.job_title = this.form.job_title2;
          _editMyCompany({ ...formCopy })
            .then(() => {
              this.editLoading = false;
              this.$Message.success("success");
              // refresh
              this.editable = false;
              this.queryMyCompany();
            })
            .catch(({ message }) => {
              this.editLoading = false;
              this.$Message.error(message);
            });
        }
      });
    }
  },
  filters: {}
};
</script>
<style lang="less" >
  @import '../org-tree.less';

</style>
<style lang="less" scoped>
  .tree{
    width: 100%;
    overflow: auto;
    padding-bottom: 50px;
    padding-top: 10px;
  }
  .btn{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f57c00;
    padding: 6px 12px;
    color: #fff;
    cursor: pointer;
    width: fit-content;
    border-radius: 5px;
    font-size: 15px;
  }
  .d-flex{
    display: flex;
  }
  .just-content-between{
    justify-content: space-between;
  }
@media (min-width: 1600px) {
}
@media (max-width: 1600px) and (min-width: 1400px) {
}
@media (max-width: 1400px) and (min-width: 1200px) {
}
@media (max-width: 1200px) {
}
.homePage {
  background: #ffffff;
  margin-top: 120px;
  display: flex;
}
.sc-width {
  margin: 80px auto 0 auto;
}
.right{
  padding-top: 40px;
  padding-bottom: 30px;
}
.right-content{
  background: #fff;
  border-radius: 15px;
  padding: 30px 80px;
  width: 1270px;
}
.main {
  display: flex;
  margin: 0 auto;
  .left {
    width: 300px;
    .top {
      height: 210px;
      background: linear-gradient(135deg, #a25d72, #2d3b81);
      opacity: 0.9;
      border-radius: 5px;
      .logo {
        height: 100px;
        width: 100px;
      }
      .name {
        font-size: 16px;

        font-weight: 500;
        color: #ffffff;
        line-height: 18px;
      }
    }
    .info {
      .item {
        height: 60px;
        text-align: center;
        padding-top: 16px;
        font-size: 16px;

        font-weight: 500;
        color: #53575c;
        line-height: 18px;
      }
      .active {
        background: #ef7f21;
        opacity: 0.15;
      }
    }
  }
  .right {
    /*width:1000px;*/
    .title {
      font-size: 24px;

      font-weight: 500;
      color: #ef7f21;
      line-height: 18px;
      margin: 40px 0 20px 50px;
    }
    .divided {
      width: 90%;
      height: 1px;
      background: #ef7f21;
      margin-bottom: 30px;
    }
  }
}
</style>
